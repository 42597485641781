import React, { useState, useEffect } from 'react'
import styles from './Membership.module.css'
// import styles from "./Membership.module.css"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Radio } from 'antd';
import axios from 'axios'
import { capitalize } from '@mui/material';



function Membership() {

  const [ordinaryMan, setOrdinaryMan] = useState(true);
  const [associateMan, setAssociateMan] = useState(true);
  const [membershipPlans, setMembershipPlans] = useState(null)
  const navigate = useNavigate()
  const location = useLocation();


  const onChangeOrdinary = (e) => {
    console.log(`radio checked:${e.target.value}`);
    console.log(!ordinaryMan)
    setOrdinaryMan(!ordinaryMan)
  };
  const onChangeAssociate = (e) => {
    console.log(`radio checked:${e.target.value}`);
    console.log(!associateMan)
    setAssociateMan(!associateMan)
  };


  const getMembership = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/membership-plans`).then((res) => {
      console.log("here is the data");
      setMembershipPlans(res.data.result);
      console.log(res.data.result);
    });
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
    getMembership()
    // console.log(process.env.apiKey);
  }, [])
  return (
    <div className='marginTopFromNavbar'>
      {/* <div className="events_component_1">
        <div className="events_1">
          <h1>MEMBERSHIP</h1>
        </div>
        <div className="events_2">HOME - Membership</div>
      </div> */}
      <div className={styles.header_new}>
        <div className={styles.text_container}>
          <div className={styles.text}>MEMBERSHIP</div>
          <div className={styles.text2}>Home - Membership</div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "60vw", margin: "auto", marginTop: "90px" }}>

        <div className={styles.membership_head}> We'd love to have you!</div>
        <hr />
        <div className={styles.membership_desc}>As a valued member, you have access to a wide range of resources and opportunities that are designed to support your personal and professional growth.We encourage you to make the most of your membership by actively engaging with our association and taking advantage of the opportunities it offers. Stay informed about upcoming events, programs, and initiatives through our newsletters, website, and social media channels.</div>
      </div>

      <div className={styles.SIA_membership}>
        {
          membershipPlans !== null && membershipPlans.map((elem, i) => {
            console.log(elem);
            return (
              <>
                <div key={i} className={`card ${styles.card}`}>
                  <div className={styles.card_head}>
                    {elem.name}
                  </div>
                  <div style={{ height: "40px" }}>

                  </div>
                  {/* <div style={{
            paddingTop: "34px", fontWeight: "400",
            fontSize: "12px"
          }}>STARTS AT</div> */}
                  <div className={styles.card_money}>
                    S${elem.amount}.00
                  </div>
                  <p>for {capitalize(elem.category)}</p>
                  
                  <div onClick={() => {
                    if(location.pathname.includes("renewalMembership")){
                      navigate(
                        `/MembershipForm?id=${elem.id}`,
                        {
                          state: {
                            category: elem.category,
                            membershipFormType: "renewal",
                            name:elem.name
                          },
                        }
                      );
                    }else{
                      navigate(`/MembershipForm?id=${elem.id}`, { state: { category: elem.category } });
                    }
                  }}><button className={styles.button}>BE A MEMBER</button></div>

                  <hr className={styles.membership_hr} />

                  <ul style={{ height: "60%", overflow: "auto" }}>
                    {/* <li> {elem.period} months upfront payment </li> */}
                    {elem.perks && JSON.parse(elem.perks).map((elem, i) => {
                      return <><li key={i} style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}>
                        
                        <img src='/images/Check_fill.png'/>
                        <div style={{
                          textAlign: "left",
                        }}>{elem}</div>
                        
                        </li></>
                    })}
                  </ul>
                </div>
              </>
            )
          })
        }
        {/* <div className={styles.card}>
          <div className={styles.card_head}>
            Junior Member
          </div>
          <div style={{ height: "40px" }}>

          </div>
          <div style={{
            paddingTop: "34px", fontWeight: "400",
            fontSize: "12px"
          }}>STARTS AT*</div>
          <div className={styles.card_money}>
            $5/month
          </div>

          <ul>
            <li> above 12 years - 18 years </li>
          </ul>
          <hr className={styles.membership_hr} />
          <Link to="/MembershipForm"><button>BE A MEMBER</button></Link>
        </div>
        <div className={styles.card}>
          <div className={styles.card_head}>
            Ordinary Member
          </div>
          <Radio.Group style={{ width: "100%" }} onChange={onChangeOrdinary} defaultValue="ordinary_man">
            <Radio.Button style={{ width: "50%", padding: "3px 0px", display: "inline-flex", height: "40px", justifyContent: "center" }} value="ordinary_man">Man</Radio.Button>
            <Radio.Button style={{ width: "50%", padding: "3px 0px", display: "inline-flex", height: "40px", justifyContent: "center" }} value="ordinary_lady">Lady</Radio.Button>
          </Radio.Group>

          <div style={{
            paddingTop: "34px", fontWeight: "400",
            fontSize: "12px"
          }}>STARTS AT*</div>
          {ordinaryMan ?
            <>
              <div className={styles.card_money}>
                $30/month
              </div>
              <ul>
                <li> 6 months upfront payment </li>
              </ul>
            </> :
            <>
              <div className={styles.card_money}>
                $8/month
              </div>
              <ul>
                <li> 1 year upfront payment</li>
              </ul>
            </>
          }
          <hr className={styles.membership_hr} />
          <Link to="/MembershipForm"><button>BE A MEMBER</button></Link>
        </div>
        <div className={styles.card}>
          <div className={styles.card_head}>
            Associate Member
          </div>
          <Radio.Group style={{ width: "100%" }} onChange={onChangeAssociate} defaultValue="associate_man">
            <Radio.Button style={{ width: "50%", padding: "3px 0px", display: "inline-flex", height: "40px", justifyContent: "center" }} value="associate_man">Man</Radio.Button>
            <Radio.Button style={{ width: "50%", padding: "3px 0px", display: "inline-flex", height: "40px", justifyContent: "center" }} value="associate_lady">Lady</Radio.Button>
          </Radio.Group>
          <div style={{
            paddingTop: "34px", fontWeight: "400",
            fontSize: "12px"
          }}>STARTS AT*</div>
          {associateMan ?
            <>
              <div className={styles.card_money}>
                $30/month
              </div>
              <ul>
                <li> 6 months upfront payment </li>
              </ul>
            </> :
            <>
              <div className={styles.card_money}>
                $8/month
              </div>
              <ul>
                <li> 1 year upfront payment</li>
              </ul>
            </>
          }
          <hr className={styles.membership_hr} />
          <Link to="/MembershipForm"><button>BE A MEMBER</button></Link>
        </div> */}
      </div>

      <div>

      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "80vw", margin: "auto", marginBottom: "50px" }}>

        <div className={styles.membership_head_2}> Have questions about membership?</div>

        <div className={styles.membership_desc_2}>If you’re not sure which membership is right for you, connect with our team below.</div>
      </div>
      <div className={styles.Contact_us_Row} style={{ marginBottom: "100px" }}>
        <div  >

          <div className={styles.Col_1}>
            <div className={styles.call_icon} />
            <div>Give us a Call</div>
            <div>Interested in Singapore Indian Association? You are just a call away from talking to our executive.</div>
            <div>+65 62912556</div>
          </div>
        </div>
        <div span={12} >
          <div className={styles.Col_1}>
            <div className={styles.mail_icon} />
            <div className={styles.Col_2}>Write us on Email</div>
            <div className={styles.Col_2}>Send us your message on our
              email id in details.</div>
            <div>sgindian@singnet.com.sg</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Membership